import _ from "lodash";
import Head from "next/head";
import { db } from "../lib/db";

const IndexPage = ({ trails, debug, realm }) => {
  const footer = {
    links: [
      { name: "Trail Manager", href: "/manager" },
      { name: "Privacy Policy", href: "/privacy" },
      { name: "Terms of Service", href: "/terms" },
      { name: "Contact Support", href: "mailto:hi@trailbot.com" },
    ],
    social: [
      {
        name: "Facebook",
        href: "https://www.facebook.com/trailbot",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "Instagram",
        href: "https://instagram.com/trailbotapp",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "Twitter",
        href: "https://twitter.com/trailbotapp",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        ),
      },
      {
        name: "Strava",
        href: "/strava",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="m15.387 17.944-2.089-4.116h-3.065l5.154 10.172 5.15-10.172h-3.066m-7.008-5.599 2.836 5.598h4.172l-7.008-13.827-7 13.828h4.169" />
          </svg>
        ),
      },
    ],
  };

  return (
    <div>
      <Head>
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>Trailbot: MN Trail Updates App with Push Notifications</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <meta
          name="description"
          content="Minnesota mountain and fat bike trail conditions and notifications"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <header>
        <div className="bg-green h-20"></div>
        <div className="absolute top-21 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
          <img src="/images/trailbot_logo.svg" alt="Trailbot" />
        </div>

        <div className="pt-40 lg:pt-20 bg-hero-cyclist bg-cover bg-center bg-green bg-blend-multiply">
          <div className="container mx-auto flex flex-col lg:flex-row">
            <div className="w-full lg:w-3/5 xl:pl-10 flex flex-col justify-center lg:items-start pl-0 items-center">
              <h1 className="text-4xl md:text-6xl text-cream font-bold text-center lg:text-left">
                Know when to go.
              </h1>
              <h2 className="mt-5 px-4 text-xl md:text-3xl text-cream text-center md:text-left md:px-0">
                Official trail reports for {trails.length} off-road MTB trails
                across Minnesota and Western Wisconsin.
              </h2>
              <div className="mt-10 flex flex-wrap justify-center gap-4">
                <a
                  className=""
                  href="https://apps.apple.com/app/apple-store/id1559573510?pt=122832432&ct=dotcom&mt=8"
                >
                  <img
                    className="h-16"
                    src="/images/app_store_badge.svg"
                    alt="Download on the App Store"
                  />
                </a>
                <a className="" href="/play">
                  <img
                    className="block h-16"
                    src="/images/google_play_badge.png"
                    alt="Get it on Google Play"
                  />
                </a>
              </div>
            </div>
            <div className="w-full lg:w-3/5 mt-20 lg:mt-10 px-4 xl:pr-10 flex justify-center">
              <img
                src="/images/screenshot.png"
                alt="Trailbot App Screenshot"
                className="w-full mb-4 md:w-2/3 rounded-xl"
              />
            </div>
          </div>
        </div>
      </header>

      {/* <section className="bg-cream">
        <div className="container mx-auto px-4 py-10 flex flex-col lg:flex-row justify-center items-center text-center lg:text-left">
          <div className="lg:pr-14">

            <p className="mt-4 text-gray-400 tracking-wide leading-8 text-base lg:text-lg">
              Trailbot users are happy users! With Apple App Store & Google Play
              ratings of 4.9/5, we take pride in continuing to improve our
              users’ experience with the trails they love.
            </p>
          </div>
          <div className="mt-10 lg:mt-0 p-14 bg-gray-500 bg-opacity-10 rounded-lg">
            <p>Placeholder</p>
          </div>
        </div>
      </section> */}

      <Reviews />

      <section className="flex py-12 items-center justify-center text-center bg-white ">
        <div className="container mx-auto">
          <h2 className="mb-8 text-2xl uppercase text-green font-bold tracking-wide">
            Included Trail Systems
          </h2>
          <ul className="text-gray-700 tracking-wide text-lg grid md:grid-cols-3 gap-4 grid-cols-2">
            {trails.map((t) => (
              <li key={t.trailId}>
                <a
                  href={t.url}
                  target="_blank"
                  className="font-bold text-green-darker"
                  rel="noopener"
                >
                  {t.trailName}
                </a>
                <br />
                {t.city}, {t.state.replace("Minnesota", "MN")}
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className="bg-othergreen">
        <div className="container mx-auto md:max-w-xl flex flex-col justify-center items-center p-8">
          <div className="text-center">
            <h2 className="text-2xl md:text-xl uppercase text-green-light font-bold tracking-wide">
              For Trail Managers
            </h2>
          </div>

          <p className="py-6 text-cream tracking-wide leading-6 text-lg">
            <strong>
              Are you a trail manager struggling to keep riders up-to-date on
              the latest conditions?
            </strong>
          </p>
          <p className="pb-6 text-cream tracking-wide leading-6 text-base">
            Trailbot offers free tools to notify riders of status changes,
            update your Facebook and Twitter pages, and embed trail conditions
            on your existing web site. All at once.
          </p>

          <div className="mt-6">
            <a
              href="mailto:hi@trailbot.com?subject=Trail%20Manager%20Inquiry"
              className="bg-green hover:bg-green-lighter text-cream text-lg font-semibold py-2 px-10 rounded"
            >
              Contact us to learn more
            </a>
          </div>
        </div>
      </section>

      <footer className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
          <nav
            className="-mx-5 -my-2 flex flex-wrap justify-center"
            aria-label="Footer"
          >
            {footer.links.map((item) => (
              <div key={item.name} className="px-5 py-2">
                <a
                  href={item.href}
                  className="text-base text-gray-500 hover:text-gray-900"
                >
                  {item.name}
                </a>
              </div>
            ))}
          </nav>
          <div className="mt-8 flex justify-center space-x-6">
            {footer.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-gray-400 hover:text-gray-500"
                title={item.name}
                target="_blank"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-8 w-8" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-8 text-center text-base text-gray-400">
            &copy; {new Date().getFullYear()} Crossover Ventures, LLC.{" "}
            {debug ? realm : ""}
          </p>
        </div>
      </footer>
    </div>
  );
};

const Reviews = () => {
  return (
    <section className="bg-cream">
      <div className="max-w-3xl mx-auto">
        <div className="py-8 px-4">
          <blockquote className="md:flex-grow md:flex md:flex-col">
            <div className="relative text-lg font-medium text-gray-700 md:flex-grow">
              <svg
                className="absolute top-2 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-green-light"
                fill="currentColor"
                viewBox="0 0 32 32"
                aria-hidden="true"
              >
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <div className="relative ml-6">
                {[...Array(5).keys()].map((i) => (
                  <svg
                    key={i}
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 inline text-yellow-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                ))}
              </div>
              <p className="relative ml-6 text-base">
                This app is the only one you’ll need for trail status updates in
                Minnesota (and nearby Wisconsin). Pulls up-to-date status
                updates plus useful comments from trail bosses from their
                sources. Links for each update bring you to maps and social
                media sites for the trail. This is the only app I use now and
                should be a model for trail status updates everywhere. Thank
                you!
              </p>
            </div>
            <footer className="mt-4">
              <div className="ml-6">
                <div className="text-base font-medium text-gray-700">
                  iOS User
                </div>
                <div className="text-base font-medium text-gray-700">
                  App Store Review
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  );
};

export const getStaticProps = async function () {
  const active = await db.getActiveTrails();
  const trails = _.sortBy(active, ["trailName"]);
  const debug = process.env.DEBUG === "true";
  const realm = process.env.REALM;

  return {
    props: { trails, debug, realm },
  };
};

export default IndexPage;
